const switchedObjectNameOrLabel = (obj) => {
  const useMarketingLabel = process.env.GATSBY_USE_MARKETING_LABEL === "true"

  if (useMarketingLabel && obj.marketingLabel !== "") {
    return obj.marketingLabel
  } else {
    return obj.name
  }
}

module.exports = { switchedObjectNameOrLabel: switchedObjectNameOrLabel }
